import { Component, OnInit, OnDestroy } from '@angular/core';

import { MENU_ITEMS } from './pages-menu';
import { UserService } from '../@core/data/user.service';
import { environment } from '../../environments/environment';
import { MENU_ITEMS_ADMIN } from './pages-menu-admin';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { CameraState } from '../store/states';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent implements OnInit, OnDestroy {
  menu = MENU_ITEMS;
  subLogginIn: Subscription;
  subCurrentUser: Subscription;
  constructor(private userService: UserService, private titleService: Title ) {
    localStorage.setItem('home', 'true');
  }
  ngOnInit(): void {
    this.titleService.setTitle( 'FG-Security Webshop' );
    this.subLogginIn = this.userService.isLoggedIn().subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
       this.userService.getCurrentUser().then(user => {
          if (user.email == environment.infoAccount || user.email == environment.infoAccount2) {
            this.menu = MENU_ITEMS_ADMIN;
          }
        });
      }
    })
  }

  ngOnDestroy(): void {
    if (this.subLogginIn){
      this.subLogginIn.unsubscribe();
    }
  }
}
