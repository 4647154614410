import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { getDeepFromObject, NbAuthResult, NbAuthSocialLink, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserLocal } from '../../@core/models/user-local-model';
import { AuthService } from '../../@core/data/auth.service';
import { fakeAsync } from '@angular/core/testing';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  socialLinks: NbAuthSocialLink[] = [];

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      fname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      rpassword: ['', Validators.required],
      terms: [false, Validators.required]
    });
  }

  constructor(protected service: NbAuthService,private formBuilder: FormBuilder,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected router: Router,private authService:AuthService) {

  }

  get f() { return this.registerForm.controls; }

  register(): void {
    this.errors = this.messages = [];
    this.submitted = true;
    if (!this.registerForm.valid) {
      if (!this.registerForm.get('email').valid) {
        this.errors.push('Email not valid');
      }
      if (!this.registerForm.get('password').valid) {
        this.errors.push('Minimum length of password is 8');
      }
      this.errors.push('Please fill in all fields');
      this.showMessages =  { error:true}
      return
    }

    if (this.registerForm.get('password').value !== this.registerForm.get('rpassword').value) {
      this.errors.push('Passwords are not the same');
      this.showMessages =  { error:true}
      return
    }

    if (!this.registerForm.get('terms').value) {
      this.errors.push('Please accept terms to continue');
      this.showMessages =  { error:true}
      return
    }
    this.errors = this.messages = [];
    this.submitted = false;

    const user: UserLocal = {
      displayName: this.f.fname.value,
      email: this.f.email.value,
      canBuy: false,
      photoURL: ''
    }
    this.authService.SignUp(user,this.f.password.value).then(() =>this.router.navigate(['pages/webshop']))
    .catch(error => {
      this.submitted = true;
      this.errors.push(error.message);
    });
  }
}
