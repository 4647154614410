import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']

})
export class HomeComponent {
  translate: TranslateService;
  contactFrm: FormGroup;
  year = new Date().getUTCFullYear();
  showMessageError = false;
  previousUrl: string;
  isShowMoreCamera:boolean = false;
  isShowMoreIntercom:boolean = false;
  isShowMoreAlarm:boolean = false;
  isDesktop = true;
  constructor(private router:Router, private _translate: TranslateService, private fb: FormBuilder, private titleService: Title ) {
    this.translate = _translate;
    var ua = navigator.userAgent;
    if (localStorage.getItem('home') === 'true') {
      localStorage.setItem('home','false');
      window.location.reload();
    }

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.isDesktop = false;
    }
    
    this.titleService.setTitle( 'FG-Security' );

    this.createForm();
  }

  createForm() {
    this.contactFrm = this.fb.group({
      name: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      message: ['', Validators.required]
    });
  }

  onClickSubmit() {
    if (this.contactFrm.valid) {
      const mailText = `mailto:info@fg-security.com?subject=Messages from website&body=
      ${this.contactFrm.value.name} ${this.contactFrm.value.firstName}%0D%0A${this.contactFrm.value.email} ${this.contactFrm.value.phone}%0D%0A${this.contactFrm.value.message}`;
      window.location.href = mailText;
    } else {
      this.showMessageError = true;
    }
  }

  scrollToElement(element): void {
    const menu = document.getElementsByClassName('navbar-collapse w-100 order-3 dual-collapse2 collapse show');
    const menuLink = document.getElementsByClassName(`nav-item ${element.id}`);

    if (menu && menu.length > 0) {
      menu[0].classList.remove('show');
      menu[0]
    }
    if (menuLink && menuLink.length > 0) {
      this.removeActiveClass();
      menuLink[0].classList.add('active');
    }
    element.scrollIntoView({behavior: "smooth", inline: "nearest"});
  }

  private removeActiveClass() {
    const menu = ['home', 'services', 'about2', 'webshop', 'contact']
    menu.forEach(menuItem => {
      const menuLink = document.getElementsByClassName(`nav-item ${menuItem}`);
      menuLink[0].classList.remove('active');
    });
  }

  navigateTo(path: string) {
    this.router.navigate([`/pages/webshop/showProducts/${path}`])
  }

  setLang(lang: string) {
    this.translate.use(lang);
    const menu = document.getElementsByClassName('navbar-collapse w-100 order-3 dual-collapse2 collapse show');
    if (menu && menu.length > 0) {
      menu[0].classList.remove('show');
    }
  }

  sendMail(name: string, email: string, phone: string, message: string) {
    const mailText = `mailto:info@fg-security.com?subject=Messages from website&body=
    ${this.contactFrm.value.name} ${this.contactFrm.value.firstName}%0D%0A${this.contactFrm.value.email} ${this.contactFrm.value.phone}%0D%0A${this.contactFrm.value.message}`;
    window.location.href = mailText;
  }

  showMoreCamera() {
    this.isShowMoreCamera = true;
  }

  showLessCamera() {
    this.isShowMoreCamera = false;
  }

  showMoreIntercom() {
    this.isShowMoreIntercom = true;
  }

  showLessIntercom() {
    this.isShowMoreIntercom = false;
  }
  
  showMoreAlarm() {
    this.isShowMoreAlarm = true;
  }

  showLessAlarm() {
    this.isShowMoreAlarm = false;
  }
}
