import {
    Injectable,
  } from '@angular/core';
  import {
    HttpClient,
  } from '@angular/common/http';

  import {
    Observable,
  } from 'rxjs/internal/Observable';
import { Icon } from '../models/icon-model';
import { AngularFirestoreCollection, AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
  @Injectable({
    providedIn: 'root',
  })
  export class IconsService {
    constructor(private httpClient: HttpClient, public db: AngularFirestore) {}
    getIcons(): Observable<Icon[]> {
      const result = this.db.collection<Icon[]>('icon', ref => ref.orderBy('name', 'asc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data:any = a.payload.doc.data(); // DB Questions
          const icon: Icon = {
            id: a.payload.doc.id,
            image: data.image,
            name: data.name,
            categorie: data.categorie,
            link:''
          }
          return icon;
        }))
      )
      return result;
    }

    getIconById(id: string): Observable<Icon> {
      return this.db.collection<Icon>('icon').doc(id).get().pipe(
        map(action => {
          if (action.exists) {
            const icon: Icon = {
              id: action.id,
              name: action.data().name,
              image: action.data().image,
              categorie: action.data().categorie,
              link:''
            };
            return icon;
          }
          else {
            console.log('Id bestaat niet');
          }
        }));
    }

    deleteIcon(id: string) {
      return this.db.collection('icon').doc(id).delete();
    }

    createIcon(icon: Icon) {
      return this.db.collection('icon').add(icon);
    }

    updateIcon(icon: Icon) {
      return this.db.collection('icon').doc(icon.id).update(icon);
    }
  }
