import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS_ADMIN: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'shopping-cart-outline',
    link: '/home/home',
    home: true,
  },
  {
    title: 'Klanten',
    icon: 'people-outline',
    link: '/pages/users/users',
  },
  {
    title: 'Bestellingen',
    icon: 'list-outline',
    link: '/pages/orders/orders',
  },
  {
    title: 'Producten',
    icon: 'video-outline',
    link: '/pages/products/products',
  },
  {
    title: 'Categories',
    icon: 'grid-outline',
    link: '/pages/categories/categories',
  },
  {
    title: 'Iconen',
    icon: 'award-outline',
    link: '/pages/icons/icons',
  },
  {
    title: 'Camerasystemen',
    icon: 'video-outline',
    children: [
      {
        title: 'Al onze producten',
        link: '/pages/webshop/showProducts/camerasystemen',
      },
      {
        title: 'HD-CVI DEVICES',
        link: '/pages/webshop/showProducts/oOCJCl3uQmv1bpO3b76i',
      },
      {
        title: 'IP DEVICES',
        link: '/pages/webshop/showProducts/ACZitZHgvnsJdvT897hx',
      },
      {
        title: 'Accessoires',
        link: '/pages/webshop/showProducts/X47VhKrJRRtz2u7HAeOA',
      },
    ],
  },
  {
    title: 'Intercomsystemen',
    icon: 'monitor-outline',
    children: [
      {
        title: 'Al onze producten',
        link: '/pages/webshop/showProducts/intercomsystemen',
      },
      {
        title: '2 Draads',
        link: '/pages/webshop/showProducts/7SO2uckYJd32OjewcJEo',
      },
      {
        title: 'Netwerkkabel',
        link: '/pages/webshop/showProducts/Ie4RV8qLFEpJHcZ1WsBh',
      },
      {
        title: 'Accessoires',
        link: '/pages/webshop/showProducts/dCM8jG4ASWf0gSL0suGy',
      },
    ],
  },
  {
    title: 'Toegangssystemen',
    icon: 'shield-outline',
    children: [
      {
        title: 'Al onze producten',
        link: '/pages/webshop/showProducts/toegangssystemen',
      },
      {
        title: 'Toegangssystemen',
        link: '/pages/webshop/showProducts/uTGiZiSmcpP4BcavUCjS',
      },
      {
        title: 'Accessoires',
        link: '/pages/webshop/showProducts/M8mODdLrZ3gFqk2BTpEd',
      },
    ],
  },
  {
    title: 'Alarmsystemen',
    icon: 'lock-outline',
    children: [
      {
        title: 'Al onze producten',
        link: '/pages/webshop/showProducts/alarmsystemen',
      },
      {
        title: 'Draadloos',
        link: '/pages/webshop/showProducts/Fe90UT6kjQdK9kJzUJfS',
      },
      {
        title: 'Bekabeld',
        link: '/pages/webshop/showProducts/WuyBMHKJWwdgGCfhxNI2',
      },
    ],
  },
];
