import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-term-of-use',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.scss']
})
export class TermOfUseComponent {
  year = new Date().getUTCFullYear();
  translate: TranslateService;

  constructor(private _translate: TranslateService) {
    this.translate = _translate;
  }

  setLang(lang: string) {
    this.translate.use(lang);
    const menu = document.getElementsByClassName('navbar-collapse w-100 order-3 dual-collapse2 collapse show');
    if (menu && menu.length > 0) {
      menu[0].classList.remove('show');
    }
  }

}
