import { Icon } from '../../@core/models/icon-model';
import { Product } from '../../@core/models/product';
import { Category } from '../../@core/models/category-model';
import { Account } from '../../@core/models/account-model';

export class SetIcons {
  static readonly type = '[FG-Security] Set Icons';
  constructor(public icons: Icon[]) {}
}

export class SetProducts {
  static readonly type = '[FG-Security] Set Products';
  constructor(public products: Product[]) {}
}

export class SetCategories {
  static readonly type = '[FG-Security] Set Categories';
  constructor(public categories: Category[]) {}
}

export class SetAccount {
  static readonly type = '[FG-Security] Set Acount';
  constructor(public account: Account) {}
}

export class ResetStore {
  static readonly type = '[FG-Security] Reset store';
  constructor() {}
}