// import { Product } from 'src/app/core/models/product.model';
import { Selector, State, Action, StateContext } from '@ngxs/store';
import { Product } from '../../@core/models/product';
import { Icon } from '../../@core/models/icon-model';
import { SetIcons, SetProducts, SetCategories, SetAccount, ResetStore } from '../actions/camera.actions';
import { Category } from '../../@core/models/category-model';
import { Account } from '../../@core/models/account-model';

export class CameraStateModel {
  product: Product;
  products: Product[];
  icons: Icon[];
  categories: Category[];
  account: Account;
}

@State<CameraStateModel>({
  name: 'FgSecurity',
  defaults : {
    product : null,
    account: null,
    products: [],
    icons: [],
    categories: []
  }
})

export class CameraState {
  constructor() {
  }

  @Selector()
  static getIcons(state: CameraStateModel): Icon[] {
    return state.icons;
  }

  @Selector()
  static getProducts(state: CameraStateModel): Product[] {
    return state.products;
  }

  @Selector()
  static getCategories(state: CameraStateModel): Category[] {
    return state.categories;
  }

  @Selector()
  static getAccount(state: CameraStateModel): Account {
    return state.account;
  }

  @Action(SetProducts)
  setProducts({getState, setState}: StateContext<CameraStateModel>, {products}: SetProducts) {
    const state = getState();
      setState({
          ...state,
          products: products,
      });
  }

  @Action(SetIcons)
  setIcons({getState, setState}: StateContext<CameraStateModel>, {icons}: SetIcons) {
    const state = getState();
      setState({
          ...state,
          icons: icons,
      });
  }

  @Action(SetCategories)
  setCategories({getState, setState}: StateContext<CameraStateModel>, {categories}: SetCategories) {
    const state = getState();
      setState({
          ...state,
          categories: categories,
      });
  }

  @Action(SetAccount)
  setAcount({getState, setState}: StateContext<CameraStateModel>, {account}: SetAccount) {
    const state = getState();
      setState({
          ...state,
          account: account,
      });
  }

  @Action(ResetStore)
  ResetStore({getState, setState}: StateContext<CameraStateModel>) {
    const state = getState();
    setState({
      ...state,
      account : null,
      product : null,
      products : []
    });
  }
}