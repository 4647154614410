import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../models/user-model';
import { UserLocal } from '../models/user-local-model';

@Injectable()
export class AuthService {

  constructor(public afAuth: AngularFireAuth, public db: AngularFirestore){}

  doFacebookLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doGoogleLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doRegister(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  // Sign up with email/password
  SignUp(user: UserLocal, password: string) {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, password)
      .then((result) => {
        user.uid = result.user.uid;
        this.saveUser(user);
        this.doLogin(user.email,password).then(() => {
          this.SendVerificationMail(); // Sending email verification notification, when new user registers
        });
      });
  }

  doLogin(email,password){
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  doLogout(){
    return new Promise((resolve, reject) => {
      if(firebase.auth().currentUser){
        this.afAuth.auth.signOut();
        resolve();
      }
      else{
        reject();
      }
    });
  }

  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification();
  }

  saveUser(user: User | UserLocal) {
    if (user.photoURL === undefined) user.photoURL = null;
    return this.db.collection('user').doc(user.uid).update(user).catch((error:firebase.FirebaseError) => {
      if(error.code === 'not-found') {
        this.db.collection('user').doc(user.uid).set(user);
      }
    });
  }

}
