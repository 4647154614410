import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { HomeRoutingModule } from './home-routing.module';
import { CookieComponent } from './cookie/cookie.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';

@NgModule({
  declarations: [HomeComponent, CookieComponent, PrivacyComponent, TermOfUseComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    HomeRoutingModule,
  ],
  bootstrap: [HomeComponent]
})
export class HomeModule { }
