import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { NbLoginComponent, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../@core/data/auth.service';
import { UserService } from '../../@core/data/user.service';
import { Observable } from 'rxjs';
import { UserLocal } from '../../@core/models/user-local-model';

@Component({
  selector: 'ngx-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends NbLoginComponent implements OnInit {

  localUser: UserLocal = null;
  profileForm: FormGroup;
  userPicture:string = './../../../../assets/logo2.png';

  constructor(protected service: NbAuthService,private formBuilder: FormBuilder,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private authService: AuthService,
    private userService:UserService) {
    super(service, options, cd, router);
   
  }
  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      displayName: ['', Validators.required],
      email: ['', Validators.required],
      address: [''],
      number: [''],
      bus: [''],
      zip: [''],
      city: [''],
      country: [''],
      phone: ['']
    });

    this.userService.getCurrentUser().then(user => {
      if (user) {
        if (!!user.photoURL) {
          this.userPicture = user.photoURL;
        }
        this.userService.getLocalUser(user.uid).subscribe(localUser => {
          this.localUser = localUser;
          this.profileForm = this.formBuilder.group({
            displayName: [this.localUser.displayName, Validators.required],
            email: [this.localUser.email, Validators.required],
            address: [this.localUser.address],
            number: [this.localUser.number],
            bus: [this.localUser.bus],
            zip: [this.localUser.zip],
            city: [this.localUser.city],
            country: [this.localUser.country],
            phone: [this.localUser.phone]
          });
        });
      }
    })
  }

  saveProfile() {
    this.authService.saveUser(
      {
        uid: this.localUser.uid,
        displayName: this.profileForm.get('displayName').value,
        email: this.profileForm.get('email').value,
        address: this.profileForm.get('address').value,
        number: this.profileForm.get('number').value,
        bus: this.profileForm.get('bus').value,
        zip:this.profileForm.get('zip').value,
        city: this.profileForm.get('city').value,
        country: this.profileForm.get('country').value,
        canBuy: this.localUser.canBuy,
        photoURL: this.localUser.photoURL,
        phone:this.profileForm.get('phone').value,

      }
    ).then(() => {
      this.router.navigate(['pages/webshop']);
    })
  }
}
