import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Product } from '../models/product';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

  @Injectable({
    providedIn: 'root',
  })
  export class ProductsService {
    constructor(private httpClient: HttpClient, public db: AngularFirestore) {}
    getProducts(): Observable<Product[]> {
      const result = this.db.collection<Product[]>('product').snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data:any = a.payload.doc.data(); // DB Questions
          const product: Product = {
            id: a.payload.doc.id,
            imageBlack : data.imageBlack,
            imageWhite: data.imageWhite,
            info:data.info,
            extra:data.extra,
            link:data.link,
            name:data.name,
            new:data.new,
            online:data.online,
            price:data.price.toFixed(2),
            productIconId:data.productIconId,
            category : data.category,
            categoryName : data.categoryName,
            description : data.description,
            pictureLink1: '',
            pictureLink2: ''
          };
          return product;
        }))
      )
      return result;
    }

    getProductById(id: string): Observable<Product> {
      return this.db.collection<Product>('product').doc(id).get().pipe(
        map(action => {
          if (action.exists) {
            const product: Product = {
              id: action.id,
              name: action.data().name,
              imageBlack: action.data().imageBlack,
              imageWhite:action.data().imageWhite,
              info: action.data().info,
              extra: action.data().extra,
              link: action.data().link,
              new: action.data().new,
              online: action.data().online,
              price: action.data().price,
              productIconId: action.data().productIconId,
              category : action.data().category,
              categoryName : action.data().categoryName,
              description: action.data().description,
              pictureLink1: '',
              pictureLink2: '',
            };
            return product;
          }
          else {
            console.log('Id bestaat niet');
          }
        }));
    }
  
    createProduct(product: Product) {
      return this.db.collection('product').add(product);
    }
  
    updateProduct(product: Product) {
      return this.db.collection('product').doc(product.id).update(product);
    }
  
    deleteProduct(id: string) {
      return this.db.collection('product').doc(id).delete();
    }
  }
