import { NgModule } from '@angular/core';
import { NbMenuModule, NbLayoutModule, NbUserModule, NbActionsModule, NbSearchModule, NbContextMenuModule, NbButtonModule, NbSelectModule, NbIconModule, NbSidebarService, NbSidebarModule, NbThemeModule, NbToastrService, NbToastrModule, NbDialogModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { LayoutService } from '../@core/utils';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
];

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    MiscellaneousModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbToastrModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NB_MODULES
  ],
  declarations: [
    PagesComponent,
  ],

  providers: [
    LayoutService,
    NbSidebarService,
  ]
})
export class PagesModule {
}
