import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  NbLoginComponent,
  NbAuthSocialLink,
  getDeepFromObject,
  NbAuthResult,
  NbAuthService,
  NB_AUTH_OPTIONS
} from '@nebular/auth';
import {
  Router
} from '@angular/router';
import { AuthService } from '../../@core/data/auth.service';
import { Subscription } from 'rxjs';
import { User } from '../../@core/models/user-model';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends NbLoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];
  rememberMe = false;
  subLogin: Subscription;

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  constructor(protected service: NbAuthService,private formBuilder: FormBuilder,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private authService:AuthService) {
    super(service, options, cd, router);
    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
  }

  login(): void {
    this.submitted = false;
    this.messages = [];
    this.showMessages =  {}
    this.errors = [];

    if (!this.loginForm.valid) {
      this.errors.push('Please fill in all fields');
      this.showMessages =  { error:true}
      return
    }
    this.authService.doLogin(this.loginForm.get('email').value, this.loginForm.get('password').value).then((gUser) => {
      this.router.navigate(['pages/webshop/showProducts/camerasystemen']).then(x => {
        window.location.reload()
      });
    }).catch(error => {
      this.errors.push(error.message);
      this.showMessages =  { error:true}
    })
  }

  loginWithFacebook() {
    this.authService.doFacebookLogin().then((gUser) => {
      const user : User = {
        displayName: gUser.user.displayName,
        email: gUser.user.email,
        phoneNumber: gUser.user.phoneNumber,
        photoURL: gUser.user.photoURL,
        uid: gUser.user.uid,
      }
      this.authService.saveUser(user);
      this.router.navigate(['pages/webshop/showProducts/camerasystemen']).then(x => {
        window.location.reload()
      });
    });
  }

  loginWithGoogle() {
    this.authService.doGoogleLogin().then((gUser) => {
      const user : User = {
        displayName: gUser.user.displayName,
        email: gUser.user.email,
        phoneNumber: gUser.user.phoneNumber,
        photoURL: gUser.user.photoURL,
        uid: gUser.user.uid,
      }
      this.authService.saveUser(user);
      this.router.navigate(['pages/webshop/showProducts/camerasystemen']).then(x => {
        window.location.reload()
      });
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  ngOnDestroy(): void {
    if (this.subLogin) {
      this.subLogin.unsubscribe();
    }
  }
}
