import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ToasterService, ToasterModule } from 'angular2-toaster';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatCardModule, MatToolbarModule, MatInputModule, MatButtonModule, MatSelectModule } from '@angular/material';
import { AngularFireModule } from '@angular/fire';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import * as StatesAndModels from './../store/states/index';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NbMenuModule, NbLayoutModule, NbUserModule, NbActionsModule, NbSearchModule, NbSidebarModule, NbContextMenuModule,
NbButtonModule, NbSelectModule, NbIconModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
// import { LayoutService } from '../core/services/layout.service';
import { ThemeModule } from '../@theme/theme.module';
import { LayoutService } from '../@core/utils';
import { environment } from '../../environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  ThemeModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NB_MODULES,
  ],
  exports : [
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    AngularFireModule,
    ToasterModule,
    NgxsModule,
    HttpClientModule,
    NgxsReduxDevtoolsPluginModule,
    NgxsLoggerPluginModule,
    NgxsRouterPluginModule,
    TranslateModule,
    MatSelectModule,
    NbMenuModule,
    NbSidebarModule,
    ThemeModule
  ],
  providers : [
    ToasterService,
    LayoutService
  ]
})
export class SharedModule { }
