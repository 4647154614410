import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../../../@core/data/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/data/auth.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  userPictureOnly: boolean = false;
  userMenu = [ { title: 'Inloggen' }, { title: 'Registreren' } ];
  isLoggedIn: Observable<boolean>;
  subMenuItem: Subscription;
  userName: string ='Welkom';
  userPicture:string = './../../../../assets/logo2.png';
  subLogginIn: Subscription;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserService,
              private authService: AuthService,
              private router: Router,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this
    this.subLogginIn =this.userService.isLoggedIn().subscribe((isLoggedIn:boolean) =>{
      if(isLoggedIn) {
        this.userService.getCurrentUser().then(user => {
          if (!!user.displayName){
            this.userName = user.displayName;
          }
          if (!!user.photoURL) {
            this.userPicture = user.photoURL;
          }
        });

        this.userMenu = [ { title: 'Mijn bestelling' },{ title: 'Profiel' }, { title: 'Uitloggen' } ];
      } else {
        this.userMenu = [ { title: 'Inloggen' }, { title: 'Registreren' } ];
      }
    });
    this.subMenuItem = this.menuService.onItemClick().subscribe(( event ) => {
      this.onItemSelection(event.item.title);
    })
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  onMenuItemClick($event) {

  }

  onItemSelection( title ) {
    if ( title === 'Uitloggen' ) {
      this.authService.doLogout().then(() =>window.location.reload());
    } else if ( title === 'Profiel' ) {
      this.router.navigate(['auth/profile']);
    } else if ( title === 'Mijn bestelling' ) {
      this.router.navigate(['/pages/webshop/cart']);
    } else if ( title === 'Inloggen' ) {
      this.router.navigate(['auth/login']);
    } else if ( title === 'Registreren' ) {
      this.router.navigate(['auth/register']);
    }
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  ngOnDestroy(): void {
    if(this.subMenuItem) {
      this.subMenuItem.unsubscribe();
    }

    if (this.subLogginIn) {
      this.subLogginIn.unsubscribe();
    }
  }
}
