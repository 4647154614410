import {
  Injectable
} from "@angular/core";
import {
  AngularFireAuth
} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {
  AngularFirestore
} from '@angular/fire/firestore';
import {
  resolve
} from 'url';
import {
  from, Observable
} from 'rxjs';
import { UserLocal } from '../models/user-local-model';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth
  ) {}

  isLoggedIn() {
    const promise = new Promise <boolean> ((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
    });
    return from(promise);
  }

  getCurrentUser() {
    return new Promise < any > ((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  getLocalUser(id: string): Observable<UserLocal> {
    return this.db.collection<UserLocal>('user').doc(id).get().pipe(
      map(action => {
        if (action.exists) {
          const user: UserLocal = {
            uid: action.id,
            displayName: action.data().displayName,
            canBuy: action.data().canBuy,
            email: action.data().email,
            photoURL: action.data().photoURL,
            address: action.data().address,
            bus: action.data().bus,
            city: action.data().city,
            country: action.data().country,
            number: action.data().number,
            phone: action.data().phone,
            zip: action.data().zip
          };
          return user;
        }
        else {
          console.log('Id bestaat niet');
        }
      }));
  }

  getLocalUsers(): Observable<UserLocal[]> {
    const result = this.db.collection<UserLocal[]>('user').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data:any = a.payload.doc.data(); // DB Questions
        const userLocal: UserLocal = {
          uid: data.uid,
          displayName: data.displayName,
          canBuy: data.canBuy,
          email: data.email,
          photoURL: data.photoURL,
          address: data.address,
          bus: data.bus,
          city: data.city,
          country: data.country,
          number: data.number,
          phone: data.phone,
          zip: data.zip
        }
        return userLocal;
      }))
    )
    return result;
  }

  updateCurrentUser(value) {
    return new Promise < any > ((resolve, reject) => {
      var user = firebase.auth().currentUser;
      user.updateProfile({
        displayName: value.name,
        photoURL: user.photoURL
      }).then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  saveLocalUser(user: UserLocal) {
    if (user.photoURL === undefined) user.photoURL = null;
    if (user.address === undefined) user.address = null;
    if (user.bus === undefined) user.bus = null;
    if (user.city === undefined) user.city = null;
    if (user.country === undefined) user.country = null;
    if (user.number === undefined) user.number = null;
    if (user.phone === undefined) user.phone = null;
    if (user.zip === undefined) user.zip = null;
    return this.db.collection('user').doc(user.uid).update(user).catch((error:firebase.FirebaseError) => {
      if(error.code === 'not-found') {
        this.db.collection('user').doc(user.uid).set(user);
      }
    });
  }
}
