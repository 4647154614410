import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { of } from 'rxjs/internal/observable/of';
@Injectable({
  providedIn: 'root',
})
export class UploadService {

  constructor(private dbStorage: AngularFireStorage) { }

  private basePath:string = '/uploads/';

  saveFile(sub: string, upload: string) {
    const path = this.basePath + sub;
    return this.dbStorage.upload(path,upload);
  }

  getFIle(sub: string, file: string) {
    const path = this.basePath + sub + '/' + file;
    return this.dbStorage.ref(path).getDownloadURL().toPromise();
  }
}


//if request.auth != null