import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Category } from '../models/category-model';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private httpClient: HttpClient, public db: AngularFirestore) {}
  getCategories(): Observable<Category[]> {
    const result = this.db.collection<Product[]>('category', cat => cat.orderBy('name','asc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data:any = a.payload.doc.data(); // DB Questions
        const category: Category = {
          id: a.payload.doc.id,
          name: data.name,
        }
        return category;
      }))
    )
    return result;
  }

  getCategoryById(id: string): Observable<Category> {
    return this.db.collection<Category>('category').doc(id).get().pipe(
      map(action => {
        if (action.exists) {
          const icon: Category = {
            id: action.id,
            name: action.data().name,
          };
          return icon;
        }
        else {
          console.log('Id bestaat niet');
        }
      }));
  }

  createCategory(category: Category) {
    return this.db.collection('category').add(category);
  }

  updateCategory(category: Category) {
    return this.db.collection('category').doc(category.id).update(category);
  }

  deleteCategory(id: string) {
    return this.db.collection('category').doc(id).delete();
  }
}
