import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'products',
      loadChildren: () => import('./products/Products.module')
        .then(m => m.ProductsModule),
    },
    {
      path: 'orders',
      loadChildren: () => import('./orders/Orders.module')
        .then(m => m.OrdersModule),
    },
    {
      path: 'categories',
      loadChildren: () => import('./categories/Categories.module')
        .then(m => m.CategoriesModule),
    },
    {
      path: 'icons',
      loadChildren: () => import('./icons/Icons.module')
        .then(m => m.IconsModule),
    },
    {
      path: 'webshop',
      loadChildren: () => import('./webshop/Webshop.module')
        .then(m => m.WebshopModule),
    },
    {
      path: 'users',
      loadChildren: () => import('./users/Users.module')
        .then(m => m.UsersModule),
    },
    // {
    //   path: '',
    //   redirectTo: 'webshop/showProducts/camerasystemen',
    //   pathMatch: 'full',
    // },
    {
      path: '**',
      component: NotFoundComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
