import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent {
  translate: TranslateService;
  year = new Date().getUTCFullYear();
  isDesktop = true;

  constructor(private _translate: TranslateService, private titleService: Title ) {
    this.translate = _translate;
    var ua = navigator.userAgent;

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.isDesktop = false;
    }
    this.titleService.setTitle( 'FG-Security Cookies' );

  }

  setLang(lang: string) {
    this.translate.use(lang);
    const menu = document.getElementsByClassName('navbar-collapse w-100 order-3 dual-collapse2 collapse show');
    if (menu && menu.length > 0) {
      menu[0].classList.remove('show');
    }
  }
}
